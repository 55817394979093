import React, { useState } from 'react';
import { Modal, Box, Button, Typography, FormControl, InputLabel, Select, MenuItem,  } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import { jwtHook } from "../../hooks/userHooks";

interface AddEventCommissionerslProps {
  event_info: any;
  getEventCommissioners: any;
}

export const AddEventCommissionersModal: React.FC<AddEventCommissionerslProps> = ({ event_info, getEventCommissioners }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [ commissioners, setCommissioners ] = useState< string | any[]>([]); // [ { id: 1, name: 'John Doe' }, { id: 2, name: 'Jane Doe' }
  const [ jwt, setJWT ] = jwtHook<any>();
  const [ status, setStatus ] = useState("");
  const [ input, setInput ] = useState<any>({
    event_id: event_info.event_id,
    user_id: ""
  }); // { event_id: 1, user_id: 1 }


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: "center"
  };

  const openModal = () => {
    setOpen(true);
    console.log(event_info);
    getCommissioners();
  };

  const addCommissioner = async () => {
    console.log(input);
    console.log("jwt", jwt);
    if(input.user_id === ""){{setStatus("Select a Commissioner"); return}}
    try{
      const response = await ABOClientApi.add_event_commissioner( 
        jwt, 
        input.event_id.toString(), 
        input.user_id.toString()
      );
      console.log(response);
      response === "ok" && setStatus(`Commissioner added to event ${event_info.event_name}`);
      getEventCommissioners();
    }catch(error){
      console.log("addCommissioner Error", error);
    };
  };

  const getCommissioners = async() =>{
    const commissionersRes: string | any[] = await ABOClientApi.get_users_by_role( "", "commissioner" );
    console.log("commissionersRes", commissionersRes);
    setCommissioners(commissionersRes);
  };

  const SelectCommnissioner: React.FC = ( ) => {
    return (
      <FormControl sx={{ width: 200, margin: "5px"}}>
        <InputLabel id="SelectOrganization_label">Select Commissioner</InputLabel>
        <Select
          labelId="SelectOrganization_label"
          id="SelectOrganization"
          value={input.user_id}
          label="Bout Event"
          onChange={(event: any) => setInput({ ...input, user_id: event.target.value })}
        >
          {
            commissioners && Array.isArray(commissioners) && commissioners.map(( commissioner: any ) => (
              <MenuItem value={commissioner.user_id}>{commissioner.user_firstname} {commissioner.user_lastname}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <Button onClick={openModal}>Add Commissioners</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={style}
      >
        <>
        <Box >
          <Typography id="modal-title" variant="h6" component="h2">
            Add Commissioner
          </Typography>
        </Box>
        <hr />
        <Box>
          <SelectCommnissioner />
        </Box>
        <Box >
          <Button variant="contained" color="primary" onClick={ ()=>addCommissioner() }>
            Add Comissioner
          </Button>
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary">
            {status}
          </Typography>
        </Box>
        </>
      </Modal>
    </>
  );
};

const AddEventCommissionersForm = () => {};