import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { AddBoutRecordModal } from '../AddBoutRecordCard/AddBoutRecordModal';
import { userLoginHook, jwtHook } from "../../hooks/userHooks";

interface BoxerInfo {
  boxer_firstname: string;
  boxer_image: string;
  boxer_weight: string;
  // Add other boxer properties as needed
}

interface Bout {
  id: number;
  boxer_id_1_info: BoxerInfo;
  boxer_id_2_info: BoxerInfo;
  judges?: string[];
  commissioner?: string;
  referee?: string;
}

interface ViewEventBoutsProps {
  event_info: any;
}

export const ViewEventBouts: React.FC<ViewEventBoutsProps> = ({ event_info }) => {
  const [ user, setUser ]: any = userLoginHook();
  return (
    <>
      <Box>
        <div style={{
          // display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
          width: "100%"
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Event Bouts
            {
              user && JSON.parse(user).user_role === "abo_admin" && <AddBoutRecordModal event_id={event_info.event_id}/>
            }
          </Typography>
        </div>
        {
          event_info.bouts.map(( bout:Bout ) => (
            <Box key={bout.id} mb={3} style={{ padding: "5px", border: "1px white solid"}}>
              <Card sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography id="modal-title" variant="h6" component="h2" gutterBottom> 
                    {bout.boxer_id_1_info.boxer_firstname}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{ width: 150, height: 150, objectFit: 'cover' }}
                    image={bout.boxer_id_1_info.boxer_image}
                    alt={bout.boxer_id_1_info.boxer_firstname}
                  />
                  <Typography variant="body2" mt={1}>
                    {/* Add extra boxer information here */}
                    Weight: {bout.boxer_id_1_info.boxer_weight} lbs
                  </Typography>
                </Box>
                <CardContent sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="h4" component="div">
                    VS
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography id="modal-title" variant="h6" component="h2" gutterBottom> 
                    {bout.boxer_id_2_info.boxer_firstname}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{ width: 150, height: 150, objectFit: 'cover' }}
                    image={bout.boxer_id_2_info.boxer_image}
                    alt={bout.boxer_id_2_info.boxer_firstname}
                  />
                  <Typography variant="body2" mt={1}>
                    {/* Add extra boxer information here */}
                    Weight: {bout.boxer_id_2_info.boxer_weight} lbs lbs
                  </Typography>
                </Box>
              </Card>
              
              {/* Officials Information */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Typography variant="body2">Judges: {bout.judges?.join(', ') || "TBA"}</Typography>
                <Typography variant="body2">Commissioner: {bout.commissioner || "TBA"}</Typography>
                <Typography variant="body2">Referee: {bout.referee || "TBA"}</Typography>
              </Box>
            </Box>
          ))
        }
      </Box>
    </>
  );
};