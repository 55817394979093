import * as React from 'react';
import { Button, Box, Typography, Modal, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, } from '@mui/material';
import { styled } from '@mui/system';
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Styled components for custom styling
const BoxerProfileCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  maxWidth: 1200,
  margin: 'auto',
  marginTop: theme.spacing(2),
}));

const ProfileImage = styled('img')({
  width: 150,
  height: 150,
  objectFit: 'cover',
});

const StatsBox = styled(Box)({
  flexGrow: 1,
  paddingLeft: '16px',
});

const KOCount = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  backgroundColor: 'rgba(0,0,0,0.5)',
  color: 'white',
  padding: theme.spacing(0.5),
  borderRadius: '4px',
}));

interface BoxerRecordsProps {
  boxerInfo: any;
};

export const BoxerRecord = ({boxerInfo}: BoxerRecordsProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenRecord = () => {
    console.log("opening records");
    // getBoxerBouts();
    handleOpen();
  };

  const getBoxerBouts = async () => {
    console.log("boxer id", boxerInfo.boxer_id);
    try {
      const response = await ABOClientApi.get_bouts_by_boxer_id("", boxerInfo.boxer_id);
      console.log("boxer bouts", response);
      console.log(response);
    } catch (error) {
      console.log("getBoxerBouts Error", error);
    }
  };
  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY');
  };
  const calculateAge = (dobTimestamp: number): number => {
      // Convert Unix timestamp (which is in seconds) to milliseconds for JavaScript Date object
      const birthDate = new Date(dobTimestamp * 1000);
      
      const today = new Date();
      
      let age = today.getFullYear() - birthDate.getFullYear();
      
      // Check if birthday hasn't occurred this year
      const hasBirthdayOccurredThisYear = 
        today.getMonth() > birthDate.getMonth() || 
        (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());
    
      if (!hasBirthdayOccurredThisYear) {
        age--;
      }
      return age;
  };

  return (
    <div>
      <Button variant="contained" onClick={()=>handleOpenRecord()}>Record</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BoxerProfileCard>
          <KOCount variant="body2">Bouts: {boxerInfo.bouts.length}</KOCount>
          <Box position="relative">
            {/* Change here: Use ProfileImage directly as an img */}
            <ProfileImage 
              src={boxerInfo.boxer_image}
              alt={`${boxerInfo.boxer_firstname}'s profile`} 
            />
          </Box>
          <StatsBox>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {boxerInfo.boxer_firstname} {boxerInfo.boxer_lastname}(ID: {boxerInfo.boxer_id})
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Age: {calculateAge(boxerInfo.boxer_dob)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Division: {boxerInfo.boxer_division}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Weight: {boxerInfo.boxer_weight}lbs
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Height: {boxerInfo.boxer_height} in
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Reach: {boxerInfo.boxer_reach} in
              </Typography>
              <hr />
              <Typography variant="body2" color="text.secondary">
                Bouts: 
              </Typography>
              <BoutTable boxerInfo={boxerInfo} />
            </CardContent>
          </StatsBox>
        </BoxerProfileCard>
      </Modal>
    </div>
  );
}

interface Bout {
  opponent: string;
  date: string;
  location: string;
  result: string;
  scores?: {judge1: string, judge2: string, judge3: string};
}

interface BoxerProfileCardProps {
  boxerInfo: {
    bouts: any[];
    boxer_id: number;
    boxer_firstname: string;
    boxer_lastname: string;
  };
}

const BoutTable: React.FC<BoxerProfileCardProps> = ({ boxerInfo }) => {
  const [expanded, setExpanded] = React.useState<number | null>(null);
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  
  const handleExpandClick = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY');
  };

  console.log("boxerInfo", boxerInfo);


  return (
    <TableContainer component={Paper}>
      <Table aria-label="bouts table">
        <TableHead>
          <TableRow>
            <TableCell>Opponent</TableCell>
            <TableCell>Weight/In(LBS)</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Rounds</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Weight Class</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {boxerInfo.bouts.map((bout, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  {
                    boxerInfo.boxer_id === bout.bout_boxer_id_1 ? 
                       `${bout.boxer_id_2_info.boxer_firstname} ${bout.boxer_id_2_info.boxer_lastname}(${bout.boxer_id_2_info.boxer_id})`
                       : 
                       `${bout.boxer_id_1_info.boxer_firstname} ${bout.boxer_id_1_info.boxer_lastname} | (ID: ${bout.boxer_id_1_info.boxer_id})`
                  }
                </TableCell>
                <TableCell>{boxerInfo.boxer_id === bout.bout_boxer_id_1 ? bout.bout_boxer_id_2_weight : bout.bout_boxer_id_1_weight}lbs</TableCell>
                <TableCell>{formatDate(bout.bout_date_time)}</TableCell>
                <TableCell>{bout.event.event_name}</TableCell>
                <TableCell>{JSON.parse(bout.event.event_country).label}</TableCell>
                <TableCell >{bout.bout_actual_rounds}/{bout.bout_planned_rounds}</TableCell>
                <TableCell style={{  backgroundColor: boxerInfo.boxer_id === bout.bout_boxer_id_winner ? 'green' : 'red'}}>
                  {boxerInfo.boxer_firstname} {boxerInfo.boxer_lastname} {<br />} {boxerInfo.boxer_id === bout.bout_boxer_id_winner ? "Won" : "Lost"} By {bout.bout_result}
                </TableCell>
                <TableCell>{bout.bout_weight_class}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleExpandClick(index)}
                  >
                    {expanded === index ? <>scores<ExpandLessIcon /> </> : <>scores<ExpandMoreIcon /></>}
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                  <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <Typography variant="h6" gutterBottom component="div">
                        Scores
                      </Typography>
                      {bout.scores.lenght > 0 ? (
                        <Box>
                          <Typography>Judge 1: {bout.scores.judge1}</Typography>
                          <Typography>Judge 2: {bout.scores.judge2}</Typography>
                          <Typography>Judge 3: {bout.scores.judge3}</Typography>
                        </Box>
                      ) : (
                        <>
                          <Typography>No scores available.</Typography>
                          {jwt && user.user_role === "abo_admin" && <Button variant="contained">Add Score</Button>}
                        </>
                      )}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};