import React, { useState } from 'react';
import { Modal, Box, Button, Typography, Tabs, Tab } from '@mui/material';
import { ViewEventBouts } from './ViewEventBouts';
import { ViewEventCommissioners } from './ViewEventCommissioners';

interface EventsMoreInfoModalProps {
  event_info: any[]
}

export const EventsMoreInfoModal: React.FC<EventsMoreInfoModalProps> = ({event_info}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'scroll',
    height:  600,
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Button onClick={handleOpen}>More Info</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            <EventInfoTabs event_info={event_info}/>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface EventInfoTabsProps {
  event_info: any
}

const EventInfoTabs: React.FC<EventInfoTabsProps> = ( {event_info} ) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Bouts" {...a11yProps(0)} />
          <Tab label="Commisioners" {...a11yProps(1)} />
          <Tab label="Judges" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ViewEventBouts event_info={event_info} />   
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ViewEventCommissioners event_info={event_info} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
    </Box>
  );
}