import React, { useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { styled } from "@mui/material/";
import { AddBoutRecordCard } from "./AddBoutRecordCard";

interface AddBoutRecordModal {
  event_id: any;
};

export const AddBoutRecordModal: React.FC<AddBoutRecordModal> = ({event_id}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

// Define a styled Box component with our sporty design
const SportyBox = styled(Box)({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px',
  borderRadius: '10px', // Rounded corners for a sleek look
  border: '3px solid transparent',
  backgroundImage: 'linear-gradient(black, black), radial-gradient(circle at top left, #999999, #000000)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  overflow: 'hidden', // To ensure no content spills out of our sporty border

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    margin: '-3px', // To ensure the border effect covers the entire border area
    borderRadius: 'inherit', // Inherits the Box's borderRadius
    background: 'linear-gradient(to right, #999999, #000000)', // A gradient that feels sporty
    transition: 'transform 0.2s ease', // Smooth transition for any interactive states
  },

  '&:hover::before': {
    transform: 'rotate(2deg)', // A slight tilt for dynamic interaction
  }
});

  return (
    <>
      <Button onClick={handleOpen}>Add Bout</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
      <>
        <SportyBox>
          <AddBoutRecordCard event_id={event_id}/>
        </SportyBox>
      </>
      </Modal>
    </>
  );
};